import revive_payload_client_9SsrxlQ1f1 from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.0_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_axios@1.7.2_eslint@9.4.0_floating-v_e6zmuic24pogcue35wof4m73yy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eTO67dHQw6 from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.0_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_axios@1.7.2_eslint@9.4.0_floating-v_e6zmuic24pogcue35wof4m73yy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_gMBL44rTXr from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.0_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_axios@1.7.2_eslint@9.4.0_floating-v_e6zmuic24pogcue35wof4m73yy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_NYpytIljmI from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.0_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_axios@1.7.2_eslint@9.4.0_floating-v_e6zmuic24pogcue35wof4m73yy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_BL575NX6Q9 from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.0_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_axios@1.7.2_eslint@9.4.0_floating-v_e6zmuic24pogcue35wof4m73yy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_36AP3EHOtu from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.0_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_axios@1.7.2_eslint@9.4.0_floating-v_e6zmuic24pogcue35wof4m73yy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_FebLHoYF3B from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import documentDriven_sKGRUPos9J from "/usr/local/app/node_modules/.pnpm/@nuxt+content@2.12.1_nuxt@3.12.0_rollup@4.18.0_vue@3.4.27/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import nuxt_plugin_48K6TB8H2Z from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.3_rollup@4.18.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import provideState_pSwTWkWqpl from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.0.0_graphql-tag@2.12.6_graphql@16.8.1_nuxt@3.12.0_rollup@4.18.0_typescript@5.4.5_vite@5.2.13/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import plugin_oNkOX812Fm from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.18.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_mkRk4pFumn from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_8xAvbkzmyf from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.7.0_rollup@4.18.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import plugin_HMUycbzStU from "/usr/local/app/modules/pino/runtime/plugin.ts";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import chunk_reload_client_qEjhVE6KuK from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.0_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_axios@1.7.2_eslint@9.4.0_floating-v_e6zmuic24pogcue35wof4m73yy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import collections_pRcEP3swaI from "/usr/local/app/plugins/collections.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import supportAgent_3y6YttNG9k from "/usr/local/app/plugins/supportAgent.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  revive_payload_client_9SsrxlQ1f1,
  unhead_eTO67dHQw6,
  router_gMBL44rTXr,
  payload_client_NYpytIljmI,
  check_outdated_build_client_BL575NX6Q9,
  components_plugin_KR1HBZs4kY,
  prefetch_client_36AP3EHOtu,
  plugin_client_FebLHoYF3B,
  documentDriven_sKGRUPos9J,
  nuxt_plugin_48K6TB8H2Z,
  provideState_pSwTWkWqpl,
  plugin_oNkOX812Fm,
  i18n_mkRk4pFumn,
  plugin_8xAvbkzmyf,
  unocss_MzCDxu9LMj,
  plugin_HMUycbzStU,
  plugin_xDtleEcACi,
  chunk_reload_client_qEjhVE6KuK,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  collections_pRcEP3swaI,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  supportAgent_3y6YttNG9k,
  vee_validate_KcKlKmvCrJ
]